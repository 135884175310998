<template>
  <b-modal id="modal-integration-create-token" size="lg" hide-footer>
    <div class="allContentModal">

      <div class="spaceText1">
        <div class="logo">
          <svg class="fill-svg logo-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 487.274 487.274" xml:space="preserve">
            <g>
              <path d="M243.637,225.534c27.516,0,49.901-22.386,49.901-49.901s-22.386-49.901-49.901-49.901s-49.901,22.386-49.901,49.901
                    S216.121,225.534,243.637,225.534z M243.637,155.731c10.974,0,19.901,8.928,19.901,19.901s-8.928,19.901-19.901,19.901
                    s-19.901-8.928-19.901-19.901S232.663,155.731,243.637,155.731z" />
              <path d="M243.637,253.703c-46.76,0-84.803,38.042-84.803,84.803v15H328.44v-15C328.44,291.746,290.397,253.703,243.637,253.703z
                    M190.921,323.506c6.54-22.949,27.697-39.803,52.716-39.803s46.176,16.854,52.716,39.803H190.921z" />
              <path d="M466.191,84.853c-12.309,2.035-24.841,3.067-37.248,3.067c-64.868,0.002-126.841-27.241-174.504-76.708L243.637,0
                    l-10.802,11.211C185.177,60.675,123.203,87.917,58.331,87.919c-12.402,0-24.934-1.031-37.248-3.067L2.926,81.851l0.723,18.39
                    c2.836,72.218,16.806,137.371,41.52,193.648c19.922,45.366,46.815,85.071,79.932,118.013
                    c56.756,56.457,111.922,73.396,114.243,74.089l4.294,1.283l4.294-1.283c2.321-0.693,57.486-17.632,114.243-74.089
                    c33.116-32.942,60.009-72.647,79.932-118.013c24.714-56.277,38.683-121.43,41.52-193.648l0.723-18.39L466.191,84.853z
                    M342.198,389.452c-42.826,43.04-85.233,61.282-98.589,66.293c-13.202-4.909-54.673-22.658-97.352-65.112
                    C100.093,344.712,44.234,260.387,34.57,116.817c7.918,0.733,15.863,1.103,23.762,1.103c36.661-0.001,72.595-7.721,106.803-22.945
                    c28.766-12.802,55.114-30.36,78.502-52.29c23.39,21.934,49.736,39.49,78.501,52.292c34.208,15.225,70.142,22.943,106.805,22.942
                    c7.899,0,15.842-0.37,23.758-1.103C445.018,230.348,406.99,324.337,342.198,389.452z" />
            </g>
          </svg>
        </div>
        <p class="text1">Criar um novo Token</p>
      </div>
      <div class="spaceText2" v-if="!isCreated && isLoading">
        <p class="text2">Estamos gerando o seu token. Por favor, aguarde.</p>
      </div>

      <div class="spaceText2" v-if="!isCreated && !isLoading">
        <p class="text2">Crie um Token de acesso para facilitar a integração com as opções disponíveis.</p>

        <div class="input-group">
          <input type="text" class="form-control" v-model="name"
            placeholder="digite um nome de identificação para o token">
        </div>
      </div>


      <div class="spaceText2" v-if="isCreated">
        <p class="text2" v-if="expiration">O token <b>{{ name }}</b> foi gerado e terá validade até <b>{{ expiration
        }}</b>.</p>
        <p class="text2">Foi gerado o token <b>{{ name }}</b> que permanecerá válido até ser revogado.</p>
        <b-tooltip target="tooltip-token" triggers="hover">
          Clique aqui para copiar o token <b>{{ name }}</b> !
        </b-tooltip>
        <div id="tooltip-token" class="token-block text3" @click="copyToken(token)">{{ token }}</div>
      </div>

      <div class="spaceBtns" v-if="!isCreated">
        <button class="button" v-if="!isLoading" @click="toCancel">Cancelar</button>
        <button class="button2" :class="{ 'w-100': isLoading }" :disabled='isLoading'>
          <span v-if="!isLoading" @click="createToken">Criar Token</span>
          <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
      </div>

      <div class="spaceBtns" v-if="isCreated">
        <button class="button" style="width:100%" @click="toCancel">Fechar</button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import notify from "@/services/libs/notificacao";
import TokenService from "@/services/resources/TokenService";
const serviceToken = TokenService.build();

export default {
  props: [],
  components: {

  },
  data() {
    return {
      browserApi: false,
      isLoading: false,
      isCreated: false,
      name: '',
      token: '',
      expiration: null,

    }
  },
  methods: {
    toCancel() {
      this.isLoading = false;
      this.isCreated = false;
      this.name = '';
      this.token = '';

      this.$root.$emit('bv::hide::modal', 'modal-integration-create-token', '#btnShow');
    },
    createToken() {
      if (this.name.length <= 3) {
        return notify('erro', 'O token requer um nome de identificação a ser informado.');
      }
      this.isLoading = true;
      const data = { name: this.name };
      serviceToken
        .create(data)
        .then((resp) => {
          this.isLoading = false;

          if (resp.response) {
            this.isCreated = true;
            this.token = resp.data.token;
            this.$emit('refresh-tokens', true);
          } else {
            return notify('erro', 'Não foi possível criar seu token.');
          }
        });
    },
    copyToken(token) {
      this.browserApi.clipboard.writeText(token);
    },
  },
  mounted() {
    this.browserApi = navigator;
  }
}
</script>
<style lang="scss">
#modal-integration-create-token {
  z-index: 99999999999;

  .modal-header {
    border: none;
  }
}
</style>
<style lang="scss" scoped>
.token-block {
  width: 100%;
  height: auto;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 15px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.logo {
  height: auto;
  margin-bottom: 40px;
  align-items: self-start;
  display: flex;
}

.logo-svg {
  width: 66px;
}

.fill-svg {
  fill: var(--maincolor);
}

#modal-integration-create-token {
  input {
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 5px;
    height: auto;
    font-size: 14px;
    padding: 3px;
    transition: .3s;
    padding: 13px;
  }

  input:hover,
  input:focus {
    border: 1px solid #FC2B36;
    box-shadow: 0px 4px 10px rgba(148, 3, 3, 0.03);
    background: rgba(228, 0, 0, 0.05);
    color: var(--fontcolor);
    font-weight: normal;
  }

  input:focus {
    font-weight: 600;
  }

  .labelxx {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor);
    text-align: initial;
  }

  .allContentModal {
    padding: 0px 10px;
    margin: 0 auto;
    display: block;
    text-align: -webkit-center;
    margin-top: -50px;
  }

  .spaceText2 {
    margin-bottom: 20px;
  }

  .spaceText1 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .text1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.2px;
    color: var(--maincolor);
    text-align: left;
  }

  .text2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.2px;
    color: var(--fontcolor);
  }

  .text3 {
    color: var(--fontcolor);
    font-size: 15px;
  }

  .spaceBtns {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .button {
    background: var(--backgroundcolor);
    border-radius: 6px;
    width: 200px;
    height: 45px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: var(--fontcolor);
    border: none;
    margin: 0 5px;
  }

  .button2 {
    background: var(--maincolor);
    border-radius: 6px;
    width: 200px;
    height: 45px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    border: none;
    margin: 0 5px;
  }
}
</style>