<template>
  <b-modal size="lg" id="modal-trigger" hide-footer>
    <b-overlay
        :show="isLoading"
        :variant="theme"
        :opacity="0.90"
        rounded="lg"
        class="py-4"
    >
        <div class="allContentModal">
        <div class="logo">
            <img
            v-if="theme === 'dark'"
            src="../assets/img/newGDigitalWhite.png"
            alt="G Digital"
            />
            <img v-else src="../assets/img/newGDigital.png" alt="G Digital" />
        </div>
        <div>
            <div class="headerConn">
            <p class="text1" v-if="isEditing">Editar Gatilho</p>
            <p class="text1" v-else>Cadastrar novo Gatilho</p>
            </div>
        </div>
        <b-form-group class="group">
            <p>Selecione a Conexão</p>
            <div class="multi-select-container spanText">
            <multiselect
                v-model="selectedConnection"
                class="multiselect_courses"
                placeholder="Conexão"
                selectedLabel=""
                deselectLabel=""
                tagPlaceholder=""
                selectLabel=""
                track-by="id"
                label="title"
                :multiple="false"
                :taggable="false"
                :options="allConnections" 
            />
            </div>

            <p>Selecione o Curso</p>
            <div class="multi-select-container">
            <multiselect
                v-model="selectedCourse"
                class="multiselect_courses"
                placeholder="Curso"
                selectedLabel=""
                deselectLabel=""
                tagPlaceholder=""
                selectLabel=""
                track-by="id"
                label="title"
                :multiple="false"
                :taggable="false"
                :options="allCourses"
            />
            </div>

            <p>Eventos</p>
            <div class="multi-select-container">
            <multiselect
                v-model="selectedEvent"
                class="multiselect_courses"
                placeholder="Evento"
                selectedLabel=""
                deselectLabel=""
                tagPlaceholder=""
                selectLabel=""
                track-by="value"
                label="text"
                :multiple="false"
                :taggable="false"
                :options="allEvents"
            />
            </div>

            <p>Selecione os Formulários</p>
            <div class="multi-select-container">
            <multiselect
                v-model="selectedForm"
                class="multiselect_courses"
                placeholder="Formulários"
                selectedLabel=""
                deselectLabel=""
                tagPlaceholder=""
                selectLabel=""
                track-by="id"
                label="title"
                :multiple="false"
                :taggable="false"
                :options="allForms"
            >
              <template slot="afterList">
                <div v-observe-visibility="loadMoreForms"/>
              </template>
            </multiselect>
            </div>

            <p>Selecione as Tags</p>
            <div class="multi-select-container">
            <multiselect
                v-model="selectedTag"
                class="multiselect_courses"
                placeholder="Tags"
                selectedLabel=""
                deselectLabel=""
                tagPlaceholder=""
                selectLabel=""
                track-by="id"
                label="name"
                :multiple="true"
                :taggable="false"
                :options="allTags"
            >
              <template slot="afterList">
                <div v-observe-visibility="loadMoreTags"/>
              </template>
            </multiselect> 
            </div>
        </b-form-group>
        </div>
        <div class="bottomBtn">
        <button class="cancelaBtn" @click="toCancel()">Cancelar</button>
        <button @click="editTrigger()" v-if="isEditing">Editar</button>
        <button @click="createTrigger()" v-else>Cadastrar</button>
        </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";

import courseService from "@/services/resources/CourseService";
const serviceCourse = courseService.build();

import connectionService from "@/services/resources/ConnectionsService";
const serviceConnection = connectionService.build();

import triggerService from "@/services/resources/TriggerService";
const serviceTrigger = triggerService.build();

export default {
  props: ["isEditing, selectedTriggerId, eventData, updated"],
  components: {
    Multiselect,
  },
  data() {
    return {
      isEditing: false,
      selectedTriggerId: "",
      idTrigger: "",
      updated: "",

      // Loadings
      isLoading: false,

      // Fields
      selectedCourse: null,
      selectedEvent: null,
      selectedTag: null,
      selectedForm: null,
      selectedConnection: null,

      // Arrays
      allTags: [],
      lastPageTag: 0,
      currrentPageTags: 1,
      loadingTags: false,
      allConnections: [],
      allForms: [],
      lastPageForms: 0,
      currentPageForms: 1,
      loadingForms: false,
      allCourses: [],
      allEvents: [
        {
          text: "Cadastro",
          value: "student_inserted",
        },
        {
          text: "Descadastro",
          value: "student_removed",
        },
        {
          text: "Conclusão",
          value: "course_conclusion",
        },
      ],
    };
  },
  created() {
    this.$root.$on("trigger-modal-updated", async (eventData) => {
      this.selectedTriggerId = eventData.selectedTriggerId;
      this.isEditing = eventData.isEditing;
      await this.getTrigger();
    });
  },
  computed: {
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    themeIsDark() {
      return this.theme === "dark";
    },
    themeIsLight() {
      return this.theme === "light";
    },
  },
  mounted() {
    this.$root.$on("connections-updated", (updated) => {
      this.getConnections();
    });
    this.getConnections();
    this.getCourses();
  },
  watch: {
    selectedConnection(hasSelectedValue) {
      if (!!hasSelectedValue) {
        this.getTags();
        this.getForms();
      }
    },
  },
  methods: {
    toCancel() {
      this.selectedCourse = null;
      this.selectedEvent = null;
      this.selectedTag = null;
      this.selectedForm = null;
      this.selectedConnection = null;
      this.$root.$emit("bv::hide::modal", "modal-trigger", "#btnShow");
    },
    async getCourses() {
      await serviceCourse.read("/all").then((resp) => {
        this.allCourses = resp;
      });
    },
    async getTags() {
      if (this.selectedConnection && this.selectedConnection.data) {
        this.loadingTags = true;
        await serviceConnection
            .read(`/gdigital/get/tags?connection_id=${this.selectedConnection.id}`)
            .then(res => {  
                this.allTags = res.data || [];
                this.lastPageTags = res.last_page;
                this.currentPageTags = 1;
                this.loadingTags = false;
            });
      }
    },
    loadMoreTags(reached) {
      if (!reached) return;
      if (this.selectedConnection && this.selectedConnection.data && this.currentPageTags < this.lastPageTags && !this.loadingTags) {
        this.loadingTags = true;
        this.currentPageTags += 1;
        serviceConnection
          .read(`/gdigital/get/tags?connection_id=${this.selectedConnection.id}&current_page=${this.currentPageTags}`)
          .then(res => {
              this.allTags = [...this.allTags, ...res.data];
              this.loadingTags = false;
          });
      }
    },
    async getForms() {
      if (this.selectedConnection && this.selectedConnection.data) {
        this.loadingForms = true;
        await serviceConnection
        .read(`/gdigital/get/forms?connection_id=${this.selectedConnection.id}`)
        .then(res => {
          this.loadingForms = false;
          this.allForms = res.data || [];
          this.lastPageForms = res.last_page;
          this.currentPageForms = 1;
        });
      }
    },
    loadMoreForms(reached) {
      if (!reached) return;
      if (this.selectedConnection && this.selectedConnection.data && this.currentPageForms < this.lastPageForms && !this.loadingForms) {
        this.loadingForms = true;
        this.currentPageForms += 1;
        serviceConnection
          .read(`/gdigital/get/forms?connection_id=${this.selectedConnection.id}&current_page=${this.currentPageForms}`)
          .then(res => {
              this.allForms = [...this.allForms, ...res.data];
              this.loadingForms = false;
          });
      }
    },
    async getConnections() {
      await serviceConnection
        .read(
          `/list-conn-gdigital?site_id=${this.$store.getters.currentSite.currentSite.id}`
        )
        .then((resp) => {
          this.allConnections = resp;
        });
    },
    createTrigger() {
      const missingFields = [];

      if (!this.selectedConnection) {
        missingFields.push("Conexão");
      }
      if (!this.selectedCourse) {
        missingFields.push("Curso");
      }
      if (!this.selectedEvent) {
        missingFields.push("Evento");
      }
      if (!this.selectedForm) {
        missingFields.push("Formulários");
      }
      if (!this.selectedTag) {
        missingFields.push("Tags");
      }

      if (missingFields.length === 0) {
        let data = {
          connection_id: this.selectedConnection.id,
          trigger_type: "course",
          trigger_id: this.selectedCourse.id,
          site_id: this.$store.getters.currentSite.currentSite.id,
          trigger: this.selectedEvent.value,
          action_system: "new_gdigital",
          action: "upsert",
          details: {
            Forms: this.selectedForm,
            Tags: this.selectedTag,
          },
        };
        const updated = "";
        serviceTrigger
          .create(data)
          .then(() => {
            this.$bvToast.toast(`Gatilho salvo com sucesso`, {
              title: "Gatilho",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$root.$emit("integrations-updated", updated);
            this.toCancel();
            this.$bvModal.hide("modal-trigger");
          });
      } else {
        this.$bvToast.toast(
          `Campos sem preenchimento: ${missingFields.join(", ")}.`,
          {
            title: "Campos Obrigatórios",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
      }
    },
    async editTrigger() {
      let data = {
        id: this.idTrigger,
        connection_id: this.selectedConnection.id,
        trigger_type: "course",
        trigger_id: this.selectedCourse.id,
        site_id: this.$store.getters.currentSite.currentSite.id,
        trigger: this.selectedEvent.value,
        action_system: "new_gdigital",
        action: "upsert",
        details: {
          Forms: this.selectedForm,
          Tags: this.selectedTag,
        },
      };
      await serviceTrigger
        .update(data)
        .then(() => {
          this.$bvToast.toast(`Trigger editado com sucesso`, {
            title: "Trigger",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$root.$emit("integrations-updated", this.updated);
          this.$bvModal.hide("modal-trigger");
          this.toCancel();
        });
    },
    async getTrigger() {
      if (this.isEditing) {
        const triggerId = this.selectedTriggerId;
        this.isLoading = true;
        await serviceTrigger
          .read(`/${triggerId}`)
          .then(async (resp) => {
            this.idTrigger = resp.id;
            this.selectedConnection = resp.connection_id;
            this.selectedCourse = resp.trigger_id;
            this.selectedEvent = this.allEvents.find(
              (event) => event.value === resp.trigger
            );
            this.selectedTag = resp.details.Tags;
            this.selectedForm = resp.details.Forms;
            await this.getCourse();
            await this.getConnection();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    async getCourse() {
      await serviceCourse.read(`/${this.selectedCourse}`).then((resp) => {
        this.selectedCourse = resp;
      });
    },
    async getConnection() {
      await serviceConnection
        .read(`/${this.selectedConnection}`)
        .then((resp) => {
          this.selectedConnection = resp;
        });
    },
  },
};
</script>

<style lang="scss">
#modal-trigger {
  z-index: 99999999999;

  .modal-header {
    border: none;
  }
}

.multi-select-container {
  .multiselect__placeholder {
    font-weight: normal !important;
    color: var(--fontcolor) !important;
  }
}

.multiselect__single {
  color: var(--fontcolor) !important;
  font-weight: 500;
}

.multiselect__tag {
  background-color: var(--maincolor) !important;
  overflow-y: hidden !important;
}

.multiselect__tags {
  overflow-y: hidden !important;
}

.multiselect__content-wrapper {
  color: var(--fontcolor);
}

.multiselect__option--selected {
  color: var(--maincolor);
}

.multiselect__option--highlight {
  color: var(--fontcolor);
  background: var(--maincolor) !important;
}
</style>

<style lang="scss" scoped>
.url-show {
  word-wrap: break-word;
}

.logo {
  max-width: 250px;
  width: 100%;
  height: auto;
  margin-bottom: 32px;
}

.token-block {
  width: 100%;
  height: auto;
  border: 1px solid black;
  border-radius: 12px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-bottom: 15px;
}

.form-group {
  p {
    color: var(--fontcolor);
  }
}

#modal-trigger {
  input,
  select {
    border: 1px solid #ededf0 !important;
    box-sizing: border-box;
    border-radius: 5px;
    height: auto;
    font-size: 14px;
    padding: 3px;
    transition: 0.3s;
    height: 55px;
    padding: 15px 20px;
  }

  input:hover,
  input:focus,
  select:hover,
  select:focus {
    border: 1px solid #fc2b36;
    box-shadow: 0px 4px 10px rgba(148, 3, 3, 0.03);
    background: rgba(228, 0, 0, 0.05);
    color: var(--fontcolor);
    font-weight: normal;
  }

  input:focus,
  select:focus {
    font-weight: 600;
  }

  .labelxx {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--fontcolor);
    text-align: initial;
  }

  .allContentModal {
    padding: 0px 10px;
    margin: 0 auto;
    display: block;
    text-align: -webkit-center;
  }

  .spaceText2 {
    margin-bottom: 20px;
  }

  .spaceText1 {
    margin-top: 32px;
    margin-bottom: 32px;
    max-width: max-content;

    button {
      margin-top: 16px;
      margin-bottom: 12px;
      border: none;
      padding: 18px;
      padding-left: 32px;
      padding-right: 32px;
      border-radius: 8px;
      background: var(--maincolor);
      transition: 0.2s;
    }

    button:hover {
      cursor: pointer;
      font-weight: 600;
    }
  }

  .text1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.2px;
    color: var(--maincolor);
    text-align: center;
  }

  .text2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    color: var(--fontcolor);
  }

  .text3 {
    color: var(--fontcolor);
  }

  .spaceBtns {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .button {
    background: var(--backgroundcolor);
    border-radius: 6px;
    width: 200px;
    height: 45px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: var(--fontcolor);
    border: none;
    margin: 0 5px;
  }

  .button2 {
    background: var(--maincolor);
    border-radius: 6px;
    width: 200px;
    height: 45px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #ffffff;
    border: none;
    margin: 0 5px;
  }

  .gen-card {
    padding: 30px;
    background: rgba(247, 247, 247, 0.5);
    border: none;
    margin-top: 24px;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;

    p {
      text-align: left;
      width: max-content;
    }

    img {
      max-width: 28px;
    }
  }

  .card {
    padding: 30px;
    padding-top: 16px;
    padding-bottom: 16px;
    background: rgba(247, 247, 247, 0.5);
    border: none;
    margin-top: 24px;
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;

    p {
      text-align: left;
      width: max-content;
    }

    img {
      max-width: 28px;
    }
  }

  .gen-card:hover,
  .gen-card:hover,
  .card:hover {
    background: #f7f7f7;
  }

  .gen-card p:hover,
  .gen-card:hover,
  .card p:hover {
    cursor: pointer;
    font-weight: 600;
  }

  .trigger {
    margin-bottom: 0;

    p {
      text-align: left;
      width: max-content;
    }
  }

  .icon {
    width: 16px;
  }

  .icon:hover {
    cursor: pointer;
  }

  .icon-lixeira:hover {
    filter: invert(0) !important;
  }

  .icon-lixeira {
    filter: invert(50%);
  }

  .icon-edit:hover {
    filter: invert(100%) !important;
  }

  .icon-edit {
    filter: invert(50%);
  }

  .att {
    display: flex;
    justify-content: center;
  }

  .headerConn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }
  }

  button {
    border: none;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    background: var(--maincolor);
    transition: 0.2s;
    height: 40px;
  }

  button:hover {
    cursor: pointer;
    font-weight: 600;
  }

  .cancelaBtn {
    margin-right: 20px;
    background: #f7f7f7 !important;
    color: #81858e !important;
  }

  .group {
    text-align: left;
    margin-top: 16px;
    margin-bottom: 16px;

    p {
      margin: 0;
      margin-top: 16px;
    }
  }

  .singleselect_courses {
    min-width: 100%;
    height: 40px !important;
  }

  .bottomBtn {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: right;
  }
}
</style>
