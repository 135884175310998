<template>
  <b-modal id="modal-integration-eduzz" size="lg" hide-footer>
    <div class="allContentModal">

      <div class="spaceText1">
        <div class="logo">
          <svg version="1.1" viewBox="0 0 161.19 45" xmlns="http://www.w3.org/2000/svg" class="logo-svg h-8 sm:h-10">
            <defs>
              <clipPath id="a">
                <path d="m0 1080h1920v-1080h-1920z"></path>
              </clipPath>
            </defs>
            <g transform="matrix(1.3333 0 0 -1.3333 -705.6 880.36)">
              <g transform="matrix(.14031 0 0 .14031 454.95 567.63)">
                <g clip-path="url(#a)">
                  <g transform="translate(896.64 550.63)">
                    <path
                      d="m0 0h-54.287v5.801c0 15.316 9.043 22.969 26.917 22.969 18.327 0 27.37-7.653 27.37-22.969zm-26.675 49.424c-34.339 0-51.508-16.706-51.508-50.35v-20.191c0-34.57 17.864-49.655 57.076-49.655 11.138 0 21.107 1.168 30.16 3.716 8.116 2.084 9.516 3.716 9.516 11.369v3.021c0 8.117-2.094 9.969-9.516 8.117-10.443-2.548-20.422-3.716-30.16-3.716-23.443 0-33.18 6.737-33.18 23.674v5.096h64.498c8.59 0 10.906 2.094 11.832 10.674 0.232 4.642 0.464 8.126 0.464 10.211 0 31.559-16.938 48.034-49.182 48.034"
                      fill="#0d2772"></path>
                  </g>
                  <g transform="translate(1020.6 529.98)">
                    <path
                      d="m0 0c0-19.717-7.19-27.612-29.465-27.612s-29.002 7.432-29.002 27.612v19.959c0 20.18 6.959 27.843 29.002 27.843s29.465-7.663 29.465-27.843zm13.232 114.86h-2.79c-8.348 0-10.442-2.094-10.442-10.442v-49.424c-6.264 9.98-17.633 15.085-33.876 15.085-32.244 0-48.487-16.938-48.487-50.582v-19.958c0-33.181 17.633-49.655 53.13-49.655 34.107 0 52.897 16.937 52.897 49.655v104.88c0 8.348-2.084 10.442-10.432 10.442"
                      fill="#0d2772"></path>
                  </g>
                  <g transform="translate(1157 597.49)">
                    <path
                      d="m0 0h-3.021c-8.348 0-10.432-2.084-10.432-10.443v-61.95c0-16.475-6.264-22.738-26.454-22.738-20.191 0-26.686 6.263-26.686 22.738v61.95c0 8.359-2.084 10.443-10.443 10.443h-3.011c-8.358 0-10.442-2.084-10.442-10.443v-61.95c0-30.16 16.938-45.245 50.582-45.245 34.57 0 50.35 14.854 50.35 45.245v61.95c0 8.359-2.085 10.443-10.443 10.443"
                      fill="#0d2772"></path>
                  </g>
                  <g transform="translate(1269.9 503.99)">
                    <path
                      d="m0 0h-57.54c-4.874 0-7.422 1.39-7.422 4.169 0 2.095 1.621 4.179 4.864 6.274l51.518 31.318c7.19 4.41 11.833 8.357 14.149 12.295 2.557 3.947 3.716 9.284 3.716 15.548 0 17.401-7.201 23.896-26.918 23.896h-53.371c-7.421 0-9.274-1.853-9.274-9.274v-3.021c0-7.422 1.853-9.285 9.274-9.285h51.055c4.864 0 7.422-1.39 7.422-3.937 0-1.863-1.853-4.18-5.569-6.727l-51.045-31.328c-11.832-7.19-17.632-16.475-17.632-27.843 0-15.78 8.811-23.665 26.675-23.665h60.098c7.421 0 9.285 1.853 9.285 9.275v3.021c0 7.421-1.864 9.284-9.285 9.284"
                      fill="#0d2772"></path>
                  </g>
                  <g transform="translate(1381.5 503.99)">
                    <path
                      d="m0 0h-57.54c-4.874 0-7.432 1.39-7.432 4.169 0 2.095 1.632 4.179 4.874 6.274l51.508 31.318c7.2 4.41 11.833 8.357 14.159 12.295 2.548 3.947 3.706 9.284 3.706 15.548 0 17.401-7.19 23.896-26.908 23.896h-53.371c-7.421 0-9.274-1.853-9.274-9.274v-3.021c0-7.422 1.853-9.285 9.274-9.285h51.045c4.874 0 7.432-1.39 7.432-3.937 0-1.863-1.863-4.18-5.569-6.727l-51.045-31.328c-11.842-7.19-17.642-16.475-17.642-27.843 0-15.78 8.821-23.665 26.685-23.665h60.098c7.422 0 9.275 1.853 9.275 9.275v3.021c0 7.421-1.853 9.284-9.275 9.284"
                      fill="#0d2772"></path>
                  </g>
                  <g transform="translate(768.11 539.99)">
                    <path
                      d="m0 0c0-66.432-53.844-120.27-120.28-120.27-35.547 0-67.479 15.447-89.503 39.968h69.453c11.067 0 20.05 8.982 20.05 20.05 0 11.177-8.983 20.159-20.05 20.159h-93.399c-0.071 0.182-0.131 0.373-0.191 0.575-2.115 6.223-3.787 12.748-4.995 19.475h52.636c11.057 0 20.039 8.982 20.039 20.039 0 11.067-8.982 20.05-20.039 20.05h-52.636c1.188 6.686 2.87 13.282 5.045 19.475 0.061 0.181 0.131 0.373 0.201 0.564h93.339c11.067 0 20.05 8.982 20.05 20.049 0 11.057-8.983 20.04-20.05 20.04h-69.573c7.925 8.821 17.109 16.474 27.279 22.657 18.187 11.067 39.525 17.441 62.344 17.441 66.432 0 120.28-53.834 120.28-120.28"
                      fill="#ffbc00"></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <p class="text1">Entrada com Eduzz</p>
      </div>
      <div class="spaceText2" v-if="integration">
        <p class="text2">
          Parabéns, agora é só copiar a url gerada na integração para utilizar com a Eduzz.
        </p>
        <div id="tooltip-token" v-if="integration" class="token-block">
          <span class="url-show text3">
            {{ api_url }}<span v-for="(url, i) in urlparams" :key="i">{{ url }}</span>
          </span>
        </div>
      </div>
      <div class="spaceText2" v-if="!integration">
        <p class="text2">
          Quando estiver pronto para fazer a integração com a Eduzz,
          lembre-se de selecionar cuidadosamente o token e a turma ou assinatura correta para garantir que integração
          funcione corretamente.
        </p>

        <div class="input-group mt-30">
          <select class="form-control select-container" v-model="selectedTokenId">
            <option value="0" selected>Selecione um token...</option>
            <option v-for="token in tokens" :key="token.id" :value="token.id">{{ token.name }}</option>
          </select>
        </div>

        <div class="multi-select-container" v-if="allCourses && selectedTokenId">
          <b class="text3 show-flex">Selecione as Turmas</b>
          <multiselect v-model="selectedCourses" class="multiselect_courses" placeholder="Turmas" selectedLabel=""
            deselectLabel="" tagPlaceholder="" selectLabel="" track-by="id" label="title" :multiple="true"
            :taggable="false" :options="allCourses" />
        </div>
        <div class="multi-select-container" v-if="AllSignatures && selectedTokenId">
          <b class="text3 show-flex">Selecione as Assinaturas</b>
          <multiselect v-model="selectedSignatures" class="multiselect_classes" placeholder="Assinaturas" selectedLabel=""
            deselectLabel="" tagPlaceholder="" selectLabel="" track-by="id" label="title" :multiple="true"
            :taggable="false" :options="AllSignatures" />
        </div>

      </div>

      <div class="spaceBtns">
        <button class="button" v-if="!integration" @click="toCancel">Cancelar</button>
        <button class="button w-100" v-if="integration" @click="toCancel">Fechar</button>
        <button class="button2" v-if="!integration" @click="toIntegration">Gerar entrada</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import courseService from "@/services/resources/CourseService";
import signatureService from "@/services/resources/SignatureService";
const serviceCourse = courseService.build();
const serviceSignature = signatureService.build();


export default {
  props: ['tokens'],
  components: {
    Multiselect
  },
  data() {
    return {
      browserApi: false,
      token: null,
      selectedTokenId: 0,
      selectedCourses: null,
      selectedSignatures: null,
      allCourses: null,
      AllSignatures: null,
      api_url: null,
      integration: null,
      integration_url: null,
      urlparams: []
    }
  },
  methods: {
    copyToken(token) {
      this.browserApi.clipboard.writeText(token);
    },
    toCancel() {
      this.token = null;
      this.selectedCourses = null;
      this.selectedSignatures = null;
      this.api_url = null;
      this.integration = null;
      this.integration_url = null;
      this.selectedTokenId = 0;
      this.$root.$emit('bv::hide::modal', 'modal-integration-eduzz', '#btnShow');
    },
    getCourses() {
      this.loading = true;
      serviceCourse
        .read("/class/all")
        .then((resp) => {
          this.allCourses = resp;
          this.loading = false;
        });
    },
    getSignatures() {
      this.loading = true;
      serviceSignature
        .read("/all")
        .then((resp) => {
          this.AllSignatures = resp;
          this.loading = false;
        });
    },
    toIntegration() {
      this.integration = true;
      if(this.selectedCourses){
        this.urlparams.push("&course_class_id=", ...this.selectedCourses.map(course => course.id).join(","));
      }
      if (this.selectedSignatures) {
        this.urlparams.push("&signature_id=", ...this.selectedSignatures.map(signature => signature.id).join(","));
      }
    },
    filterToken() {
      const token = this.tokens.filter(token => token.id === this.selectedTokenId);
      this.api_url = `${process.env.VUE_APP_API_HOST}/external/eduzz/student?token=${token[0].token}`;
    },
  },
  watch: {
    selectedTokenId: function () {
      if (this.selectedTokenId) {
        this.filterToken();
        this.urlparams = [];
      }
    },
  },
  mounted() {
    this.getCourses();
    this.getSignatures();
    this.browserApi = navigator;
  }
}
</script>
<style lang="scss">
#modal-integration-eduzz {
  z-index: 99999999999;


  .modal-header {
    border: none;
  }
}

.multiselect {
  -ms-overflow-style: none;
}

.multiselect__tags {
  background: var(--backgroundcolor);
}

.multiselect .multiselect__input,
.multiselect__single {
  background: var(--backgroundcolor);
}

.multiselect__content-wrapper {
  background: var(--backgroundcolor);
}

.multiselect__option--selected {
  background: var(--backgroundcolor);
}
</style>

<style lang="scss" scoped>
.url-show {
  word-wrap: break-word;
}

.logo {
  height: auto;
  margin-bottom: 40px;
  align-items: self-start;
  display: flex;
}

.logo-svg {
  width: 200px;
}

.select-container option {
  background: var(--backgroundcolor) !important;
  color: var(--fontcolor);
}

.multi-select-container {
  display: block;
  margin-top: 30px;
  margin-bottom: 30px;
}

.show-flex {
  display: flex;
}

.token-block {
  width: 100%;
  height: auto;
  border: 1px solid black;
  border-radius: 12px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 15px;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-bottom: 15px;
}

#modal-integration-eduzz {
  input {
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 5px;
    height: auto;
    font-size: 14px;
    padding: 3px;
    transition: .3s;
  }

  input:hover,
  input:focus {
    border: 1px solid #FC2B36;
    box-shadow: 0px 4px 10px rgba(148, 3, 3, 0.03);
    background: rgba(228, 0, 0, 0.05);
    color: var(--fontcolor);
    font-weight: normal;
  }

  input:focus {
    font-weight: 600;
  }

  .labelxx {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor);
    text-align: initial;
  }

  .allContentModal {
    padding: 0px 10px;
    margin: 0 auto;
    display: block;
    text-align: -webkit-center;
    margin-top: -50px;
  }

  .spaceText2 {
    margin-bottom: 20px;
  }

  .spaceText1 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .text1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.2px;
    color: #ffbc00;
    text-align: left;

  }

  .text2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.2px;
    color: var(--fontcolor);
  }

  .text3 {
    color: var(--fontcolor);
    font-size: 15px;
  }

  .spaceBtns {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .button {
    background: var(--backgroundcolor);
    border-radius: 6px;
    width: 200px;
    height: 45px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: var(--fontcolor);
    border: none;
    margin: 0 5px;
  }

  .button2 {
    background: var(--maincolor);
    border-radius: 6px;
    width: 200px;
    height: 45px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    border: none;
    margin: 0 5px;
  }
}
</style>