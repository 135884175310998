<template>
<b-modal size="lg" id="modal-integration-gdigital" hide-footer>
    <div class="allContentModal">
        <div class="logo">
            <img v-if="theme === 'dark'" src="../assets/img/newGDigitalWhite.png" alt="G Digital">
            <img v-else src="../assets/img/newGDigital.png" alt="G Digital">
        </div>
        <div class="spaceText2">
            <div>
                <p class="text2 gen-card" @click="openModalGDigital()">
                    Gerenciar as Conexões com a Nova G Digital
                </p>
            </div>
            <div class="spaceText1">
                <div class="headerConn">
                    <p class="text1">Gatilhos ativos</p>
                    <button
                        @click="isEditing = false; openModalTrigger()"
                        v-b-tooltip.hover
                        v-bind:style="{ cursor: allConnections.length ? 'pointer' : 'not-allowed' }"
                        v-bind:title="allConnections.length ? '' : 'Necessário pelo menos uma integração com a G Digital.'"
                        >
                        Novo Gatilho
                    </button>
                </div>
                <div class="card" v-for="(allTriggers, index) in allTriggers" :key="index">
                    <p class="text2 trigger">Evento: {{ getTriggerText(allTriggers.trigger) }} <br> Curso: {{ getCourseName(allTriggers.trigger_id) }}</p>
                    <div class="att">
                        <img src="../assets/icons/edit.svg" class="icon icon-edit mr-3" title="Editar" @click="isEditing = true; openModalTrigger(allTriggers.id)" />
                        <img src="../assets/icons/lixeira.svg" class="icon icon-lixeira" title="Deletar" @click="deleteTrigger(index)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalGDigital />
    <ModalTrigger :eventData="eventData" />
</b-modal>
</template>

<script>
import courseService from "@/services/resources/CourseService";
const serviceCourse = courseService.build();
import triggerService from "@/services/resources/TriggerService";
const serviceTrigger = triggerService.build();

import ModalGDigital from "@/components/ModalGDigital.vue";
import ModalTrigger from "@/components/ModalTrigger.vue";
import connectionService from "@/services/resources/ConnectionsService";
const serviceConnection = connectionService.build();

export default {
    props: ['updated'],
    components: {
        ModalGDigital,
        ModalTrigger,
    },
    data() {
        return {
            allCourses: [],
            allTriggers: '',
            course: '',
            allEvents: [{
                    text: 'Cadastro',
                    value: 'student_inserted'
                },
                {
                    text: 'Descadastro',
                    value: 'student_removed'
                },
                {
                    text: 'Conclusão',
                    value: 'course_conclusion'
                }
            ],
            isEditing: false,
            selectedTriggerId: '',
            eventData: '',
            allConnections: [],
        }
    },
    computed: {
        theme() {
            return this.$store.state.globalSettings.theme;
        },
        themeIsDark() {
            return this.theme === 'dark';
        },
        themeIsLight() {
            return this.theme === 'light';
        }
    },
    methods: {
        getConnections() {
            serviceConnection
                .read(`/list-conn-gdigital?site_id=${this.$store.getters.currentSite.currentSite.id}`)
                .then((resp) => {
                    this.allConnections = resp;
                });
        },
        getCourses() {
            this.loading = true;
            serviceCourse
                .read("/class/all")
                .then((resp) => {
                    this.allCourses = resp;
                    this.loading = false;
                });
        },
        getCourseName(triggerId) {
            const course = this.allCourses.find((course) => course.course_id === triggerId);
            return course ? course.course_title : '';
        },
        openModalGDigital() {
            this.$root.$emit("bv::show::modal", "modal-gdigital", "#btnShow");
        },
        openModalTrigger(triggerId) {
            if(this.allConnections.length > 0){
                this.selectedTriggerId = triggerId;
                const eventData = {
                    selectedTriggerId: this.selectedTriggerId,
                    isEditing: this.isEditing
                };
                this.$root.$emit("trigger-modal-updated", eventData);
                this.$root.$emit("bv::show::modal", "modal-trigger", "#btnShow");
            }
        },
        getTriggers() {
            serviceTrigger
                .read("")
                .then((resp) => {
                    this.allTriggers = resp;
                });
        },
        getTriggerText(value) {
            const trigger = this.allEvents.find((event) => event.value === value);
            return trigger ? trigger.text : '';
        },
        deleteTrigger(index) {
            const triggerId = this.allTriggers[index].id;
            serviceTrigger
                .destroy(`/${triggerId}`)
                .then((resp) => {
                    this.$bvToast.toast(`Gatilho excluído com sucesso`, {
                        title: "G Digital",
                        variant: "success",
                        autoHideDelay: 5000,
                        appendToast: true,
                    });
                    this.getTriggers();
                })
                .catch(() => {});
        },
    },
    mounted() {
        this.$root.$on("integrations-updated", updated => {
            this.getTriggers();
            this.getCourses();
        });
        this.getTriggers();
        this.getCourses();

        this.$root.$on("connections-updated", updated => {
            this.getConnections();
        });
        this.getConnections();
    }
}
</script>

<style lang="scss">
#modal-integration-gdigital {
    z-index: 99999999999;

    .modal-header {
        border: none;
    }
}

.multiselect {
    -ms-overflow-style: none;
}

.multiselect__tags {
    background: var(--backgroundcolor);
}

.multiselect .multiselect__input,
.multiselect__single {
    background: var(--backgroundcolor);
}

.multiselect__content-wrapper {
    background: var(--backgroundcolor);
}

.multiselect__option--selected {
    background: var(--backgroundcolor);
}
</style>

<style lang="scss" scoped>
.url-show {
    word-wrap: break-word;
}

.logo {
    max-width: 250px;
    width: 100%;
    height: auto;
}

.select-container option {
    background: var(--backgroundcolor) !important;
    color: var(--fontcolor);
}

.multi-select-container {
    display: block;
    margin-top: 30px;
    margin-bottom: 30px;
}

.token-block {
    width: 100%;
    height: auto;
    border: 1px solid black;
    border-radius: 12px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-bottom: 15px;
}

#modal-integration-gdigital {
    input {
        border: 1px solid #ededf0;
        box-sizing: border-box;
        border-radius: 5px;
        height: auto;
        font-size: 14px;
        padding: 3px;
        transition: .3s;
    }

    input:hover,
    input:focus {
        border: 1px solid #FC2B36;
        box-shadow: 0px 4px 10px rgba(148, 3, 3, 0.03);
        background: rgba(228, 0, 0, 0.05);
        color: var(--fontcolor);
        font-weight: normal;
    }

    input:focus {
        font-weight: 600;
    }

    .labelxx {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--fontcolor);
        text-align: initial;
    }

    .allContentModal {
        padding: 0px 10px;
        margin: 0 auto;
        display: block;
        text-align: -webkit-center;
    }

    .spaceText2 {
        margin-bottom: 20px;
    }

    .spaceText1 {
        margin-top: 32px;
        margin-bottom: 32px;
    }

    .text1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        letter-spacing: 0.2px;
        color: var(--maincolor);
        text-align: center;
    }

    .text2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.2px;
        color: var(--fontcolor);
        }

    .text3 {
        color: var(--text-color);
    }

    .spaceBtns {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .button {
        background: var(--backgroundcolor);
        border-radius: 6px;
        width: 200px;
        height: 45px;
        border-radius: 3px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: var(--text-color);
        border: none;
        margin: 0 5px;
    }

    .button2 {
        background: var(--maincolor);
        border-radius: 6px;
        width: 200px;
        height: 45px;
        border-radius: 3px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: var(--text-color);
        border: none;
        margin: 0 5px;
    }
    
    .gen-card {
        border-radius: 0.25rem;
        padding: 30px;
        background-color: var(--menu-slide-color);
        border: none;
        margin-top: 24px;
        margin-bottom: 18px;
    }

    .card {
        padding: 30px;
        background: var(--menu-slide-color);
        border: none;
        margin-top: 24px;
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
        flex-direction: row !important;

        p {
            text-align: left;
            width: max-content;
        }
    }

    .card:hover,
    .gen-card:hover {
        background: var(--background2-v2);
    }

    .card p:hover,
    .gen-card:hover {
        cursor: pointer;
        font-weight: 600;
    }

    .trigger {

        margin-bottom: 0;

        p {
            text-align: left;
            width: max-content;
        }
    }

    .icon {
        color: var(--maincolor);
        width: 16px;
    }

    .icon:hover {
        cursor: pointer;
    }

    .icon-lixeira:hover {
        filter: invert(0) !important;
    }

    .icon-lixeira {
        filter: invert(50%);
    }

    .icon-edit:hover {
        filter: invert(100%) !important;
    }

    .icon-edit {
        filter: invert(50%);
    }

    .att {
        display: flex;
        justify-content: center;
    }

    .headerConn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            display: flex;
            align-items: center;
            margin-bottom: 0;
        }

        img {
            max-width: 28px;
        }

        button {
            margin-top: 16px;
            margin-bottom: 12px;
            border: none;
            padding: 18px;
            padding-left: 32px;
            padding-right: 32px;
            border-radius: 8px;
            background: var(--maincolor);
            transition: .2s;
        }

        button:hover {
            cursor: pointer;
            font-weight: 600;
        }
    }
}
</style>