import Rest from '@/services/Rest';

/**
 * @typedef {TokenService}
 */
export default class TokenService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'external/token'
}