<template>
<b-modal size="lg" id="modal-newgdigital" @hidden="toCancel" hide-footer>
    <div class="allContentModal">
        <div class="logo">
            <img v-if="theme === 'dark'" src="../assets/img/newGDigitalWhite.png" alt="G Digital">
            <img v-else src="../assets/img/newGDigital.png" alt="G Digital">
        </div>

        <div class="headerConn">
            <p class="text1">Título da conexão</p>
        </div>

        <div class="headerConn newConnection">
            <input type="text" v-model="title" placeholder="Insira o título da conexão">
        </div>

        <div class="spaceText2">
            <div class="headerConn newConnection">
                <button @click="createConnection()">Criar conexão</button>
            </div>
        </div>
    </div>

</b-modal>
</template>

<script>
import connectionService from "@/services/resources/ConnectionsService";
const serviceConnection = connectionService.build();

export default {
    components: {},
    data() {
        return {
            title: '',
            connectionId: '',
        }
    },
    computed: {
        theme() {
            return this.$store.state.globalSettings.theme;
        },
        themeIsDark() {
            return this.theme === 'dark';
        },
        themeIsLight() {
            return this.theme === 'light';
        }
    },
    methods: {
        toCancel() {
            this.title = '';
            const url = new URL(window.location.href);
            url.searchParams.delete('globalToken');
            window.history.replaceState({}, document.title, url);
        },
        createConnection() {
            let data = {
                title: this.title,
                type: 'new_gdigital',
                json: {'globalToken': this.$route.query.globalToken},
            };

            serviceConnection
                .create(data)
                .then(() => {
                    this.$bvToast.toast(`Integração salva com sucesso`, {
                        title: "G Digital",
                        variant: "success",
                        autoHideDelay: 5000,
                        appendToast: true,
                    });
                    const updated = '';
                    this.$root.$emit("connections-updated", updated);
                    this.$router.push({path: '/configuracoes'});
                    this.$bvModal.hide('modal-newgdigital');
                })
                .catch(() => {
                });
        },
    },
}
</script>

<style lang="scss">
#modal-newgdigital {
    z-index: 99999999999;

    .modal-header {
        border: none;
    }
}

.multiselect {
    -ms-overflow-style: none;
}

.multiselect__tags {
    background: var(--backgroundcolor);
}

.multiselect .multiselect__input,
.multiselect__single {
    background: var(--backgroundcolor);
}

.multiselect__content-wrapper {
    background: var(--backgroundcolor);
}

.multiselect__option--selected {
    background: var(--backgroundcolor);
}
</style>

<style lang="scss" scoped>
.url-show {
    word-wrap: break-word;
}

.logo {
    max-width: 250px;
    width: 100%;
    height: auto;
}

.select-container option {
    background: var(--backgroundcolor) !important;
    color: var(--fontcolor);
}

.multi-select-container {
    display: block;
    margin-top: 30px;
    margin-bottom: 30px;
}

.token-block {
    width: 100%;
    height: auto;
    border: 1px solid black;
    border-radius: 12px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-bottom: 15px;
}

#modal-newgdigital {
    input {
        border: 1px solid #ededf0;
        box-sizing: border-box;
        border-radius: 5px;
        height: auto;
        font-size: 14px;
        padding: 3px;
        transition: .3s;
    }

    input:hover,
    input:focus {
        border: 1px solid #FC2B36;
        box-shadow: 0px 4px 10px rgba(148, 3, 3, 0.03);
        background: rgba(228, 0, 0, 0.05);
        color: var(--fontcolor);
        font-weight: normal;
    }

    input:focus {
        font-weight: 600;
    }

    .labelxx {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: var(--fontcolor);
        text-align: initial;
    }

    .allContentModal {
        padding: 0px 10px;
        margin: 0 auto;
        display: block;
        text-align: -webkit-center;
    }

    .spaceText2 {
        margin-bottom: 20px;
    }

    .spaceText1 {
        margin-top: 32px;
        margin-bottom: 32px;
        max-width: max-content;

        button {
            margin-top: 16px;
            margin-bottom: 12px;
            border: none;
            padding: 18px;
            padding-left: 32px;
            padding-right: 32px;
            border-radius: 8px;
            background-color: #ffbc00;
            transition: .2s;
        }

        button:hover {
            cursor: pointer;
            font-weight: 600;
            background-color: #ffd050;
        }
    }

    .text1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        letter-spacing: 0.2px;
        color: var(--maincolor);
        text-align: center;
    }

    .text2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.2px;
        color: var(--fontcolor);
    }

    .text3 {
        color: var(--fontcolor);
    }

    .spaceBtns {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .button {
        background: var(--backgroundcolor);
        border-radius: 6px;
        width: 200px;
        height: 45px;
        border-radius: 3px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: var(--fontcolor);
        border: none;
        margin: 0 5px;
    }

    .button2 {
        background: var(--maincolor);
        border-radius: 6px;
        width: 200px;
        height: 45px;
        border-radius: 3px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: #FFFFFF;
        border: none;
        margin: 0 5px;
    }

    .gen-card {
        padding: 30px;
        background: rgba(247, 247, 247, 0.5);
        border: none;
        margin-top: 24px;
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;

        p {
            text-align: left;
            width: max-content;
        }

        img {
            max-width: 28px;
        }
    }

    .card {
        padding: 30px;
        padding-top: 16px;
        padding-bottom: 16px;
        background: rgba(247, 247, 247, 0.5);
        border: none;
        margin-top: 24px;
        margin-bottom: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row !important;

        p {
            text-align: left;
            width: max-content;
        }

        img {
            max-width: 28px;
        }
    }

    .gen-card:hover,
    .gen-card:hover,
    .card:hover {
        background: #f7f7f7;
    }

    .gen-card p:hover,
    .gen-card:hover,
    .card span:hover {
        cursor: pointer;
        font-weight: 600;
    }

    .trigger {

        margin-bottom: 0;

        p {
            text-align: left;
            width: max-content;
        }
    }

    .icon {
        width: 16px;
    }

    .icon:hover {
        cursor: pointer;
    }

    .icon-lixeira:hover {
        filter: invert(0) !important;
    }

    .icon-lixeira {
        filter: invert(50%);
    }

    .icon-edit:hover {
        filter: invert(100%) !important;
    }

    .icon-edit {
        filter: invert(50%);
    }

    .att {
        display: flex;
        justify-content: center;
    }

    .headerConn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        margin-bottom: 24px;

        p {
            display: flex;
            align-items: center;
            margin-bottom: 0;
        }

        button {
            margin-top: 16px;
            margin-bottom: 12px;
            border: none;
            padding: 18px;
            padding-left: 32px;
            padding-right: 32px;
            border-radius: 8px;
            background: var(--maincolor);;
            transition: .2s;
        }

        button:hover {
            cursor: pointer;
            font-weight: 600;
        }
    }

    .newConnection {

        p {
            text-align: right;
        }

        input {
            height: 40px;
            min-width: 100%;
            padding-left: 8px;

        }

        button {
            padding: 8px;
            padding-left: 12px !important;
            padding-right: 12px !important;
            margin: 0;
            max-height: 40px;
        }
    }
}
</style>
