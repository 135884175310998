<template>
  <b-modal id="modal-integration-revoke-token" hide-footer>
    <div class="allContentModal">
      <div>
        <img class="" src="@/assets/icons/delete-site.svg" alt="alert">
      </div>
      <div v-if="!loading && tokenId">
        <div class="spaceText1">
          <p class="text1">Realmente deseja revogar seu token?</p>
        </div>
        <div class="spaceText2">
          <p class="text2">Não será possível reverter qualquer integração associada ao token <b>{{ token.name }}</b>, tem
            certeza que deseja continuar?</p>
        </div>

        <div class="spaceBtns">
          <button class="button" v-if="!isLoading" @click="toCancel">Cancelar</button>
          <button class="button2" :class="{ 'w-100': isLoading }" @click="revokeToken(token.id)" :disabled="isLoading">

            <span v-if="!isLoading">Revogar</span>
            <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import notify from "@/services/libs/notificacao";
import TokenService from "@/services/resources/TokenService";
const serviceToken = TokenService.build();

export default {
  props: ['tokenId'],
  components: {

  },
  data() {
    return {
      complete: false,
      isLoading: false,
      loading: false,
      token: null
    }
  },
  methods: {
    toCancel() {
      this.complete = false;
      this.isLoading = false;
      this.loading = false;
      this.token = null;
      this.$root.$emit('bv::hide::modal', 'modal-integration-revoke-token', '#btnShow');
    },
    revokeToken(token) {
      const data = { id: token };
      this.isLoading = true;
      serviceToken
        .updateBody(data)
        .then((resp) => {
          if (resp.response) {
            return notify('sucesso', 'Seu token foi revogado!');
          }
          return notify('erro', 'Não foi possivel revogar o token!');
        })
        .finally(() => {
          this.isLoading = false;
          this.$emit('refresh-tokens', true);
          this.$root.$emit('bv::hide::modal', 'modal-integration-revoke-token', '#btnShow');
        });
    },
    getToken(token) {
      this.loading = true;
      serviceToken
        .read("/" + token)
        .then((resp) => {
          this.token = resp;
          this.loading = false;
        });
    },
  },
  watch: {
    tokenId: function () {
      this.token = this.getToken(this.tokenId);
    }
  },
}
</script>
<style lang="scss">
#modal-integration-revoke-token {
  z-index: 99999999999;

  .modal-header {
    border: none;
  }
}
</style>
<style lang="scss" scoped>
#modal-integration-revoke-token {
  input {
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 5px;
    height: auto;
    font-size: 14px;
    padding: 3px;
    transition: .3s;
  }

  input:hover,
  input:focus {
    border: 1px solid #FC2B36;
    box-shadow: 0px 4px 10px rgba(148, 3, 3, 0.03);
    background: rgba(228, 0, 0, 0.05);
    color: var(--fontcolor);
    font-weight: normal;
  }

  input:focus {
    font-weight: 600;
  }

  .labelxx {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor);
    text-align: initial;
  }

  .allContentModal {
    padding: 0px 10px;
    margin: 0 auto;
    display: block;
    text-align: -webkit-center;
  }

  .spaceText2 {
    margin-bottom: 20px;
  }

  .spaceText1 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .text1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.2px;
    color: #FC2B36;
    text-align: center;
  }

  .text2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #818181;
  }

  .spaceBtns {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .button {
    background: var(--backgroundcolor);
    border-radius: 6px;
    width: 200px;
    height: 45px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: var(--fontcolor);
    border: none;
    margin: 0 5px;
  }

  .button2 {
    background: #FC2B36;
    border-radius: 6px;
    width: 200px;
    height: 45px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    border: none;
    margin: 0 5px;
  }
}</style>