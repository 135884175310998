<template>
  <b-modal id="modal-integration-webhook" size="lg" hide-footer>
    <div class="allContentModal">
      <div class="logo">
        <svg viewBox="0 0 16 16" class="logo-svg" xmlns="http://www.w3.org/2000/svg" fill="none">
          <g fill="#06c971">
            <path
              d="M6.032 4.132c0-1.039.843-1.882 1.886-1.882.709 0 1.328.39 1.65.97a.75.75 0 101.311-.728A3.385 3.385 0 007.918.75a3.383 3.383 0 00-3.386 3.382c0 .94.385 1.79 1.003 2.402l-2.054 3.594a1.25 1.25 0 00.151 2.49h.007a1.25 1.25 0 001.146-1.75l2.369-4.144a.75.75 0 00-.249-1.005 1.879 1.879 0 01-.873-1.587z" />
            <path
              d="M7.793 5.375a1.25 1.25 0 01.125-2.494h.006a1.25 1.25 0 011.157 1.725l2.159 3.572a3.383 3.383 0 014.51 3.19 3.383 3.383 0 01-4.23 3.275.75.75 0 11.373-1.452 1.883 1.883 0 002.357-1.822 1.883 1.883 0 00-2.897-1.588.75.75 0 01-1.045-.245l-2.515-4.16z" />
            <path
              d="M2.002 10.429a.75.75 0 00-1.298-.752 3.383 3.383 0 002.932 5.073c1.61 0 2.96-1.124 3.301-2.632h4.42c.229.304.592.5 1.001.5h.007a1.25 1.25 0 000-2.5h-.007c-.409 0-.772.197-1 .5H6.271a.75.75 0 00-.75.75 1.883 1.883 0 01-1.886 1.882 1.883 1.883 0 01-1.633-2.821z" />
          </g>
        </svg>
      </div>

      <div class="spaceText1">
        <p class="text1">Entrada com Webhook</p>
      </div>
      <div class="spaceText2" v-if="integration">
        <p class="text2">
          Parabéns, agora é só copiar a url gerada na integração para utilizar com seu sistema externo.
        </p>
        <p class="text3">
          é importante adicionar a header fornecida logo abaixo para começar a fazer as requisições.
          <code class="header-token">External-Token: {{ selectedTokenValue }}</code>
        </p>
        <p class="text3">
          Parabéns, agora é só copiar a url gerada na integração para começar utilizar.
        </p>
        <div id="tooltip-token" v-if="integration" class="token-block">
          <span class="url-show text3">
            {{ api_url }}?<span v-for="(url, i) in urlparams" :key="i"><span v-if="i != 0">&</span>{{ url }}</span>
          </span>
        </div>
      </div>
      <div class="spaceText2" v-if="!integration">
        <p class="text2">
          Quando estiver pronto para fazer a integração com o Webhook,
          lembre-se de selecionar cuidadosamente o token e a turma ou assinatura correta para garantir que integração
          funcione corretamente.
        </p>

        <div class="input-group mt-30">
          <select class="form-control select-container" v-model="selectedTokenId">
            <option value="0" selected>Selecione um token...</option>
            <option v-for="token in tokens" :key="token.id" :value="token.id">{{ token.name }}</option>
          </select>
        </div>

        <div class="multi-select-container" v-if="allCourses && selectedTokenId">
          <b class="text3 show-flex">Selecione as Turmas</b>
          <multiselect v-model="selectedCourses" class="multiselect_courses" placeholder="Turmas" selectedLabel=""
            deselectLabel="" tagPlaceholder="" selectLabel="" track-by="id" label="title" :multiple="true"
            :taggable="false" :options="allCourses" />
        </div>
        <div class="multi-select-container" v-if="AllSignatures && selectedTokenId">
          <b class="text3  show-flex">Selecione as Assinaturas</b>
          <multiselect v-model="selectedSignatures" class="multiselect_classes" placeholder="Assinaturas" selectedLabel=""
            deselectLabel="" tagPlaceholder="" selectLabel="" track-by="id" label="title" :multiple="true"
            :taggable="false" :options="AllSignatures" />
        </div>

      </div>

      <div class="spaceBtns">
        <button class="button" v-if="!integration" @click="toCancel">Cancelar</button>
        <button class="button w-100" v-if="integration" @click="toCancel">Fechar</button>
        <button class="button2" v-if="!integration" @click="toIntegration">Gerar entrada</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import courseService from "@/services/resources/CourseService";
import signatureService from "@/services/resources/SignatureService";
const serviceCourse = courseService.build();
const serviceSignature = signatureService.build();


export default {
  props: ['tokens'],
  components: {
    Multiselect
  },
  data() {
    return {
      browserApi: false,
      token: null,
      selectedTokenId: 0,
      selectedTokenValue: null,
      selectedCourses: null,
      selectedSignatures: null,
      allCourses: null,
      AllSignatures: null,
      api_url: null,
      integration: null,
      integration_url: null,
      urlparams: []
    }
  },
  methods: {
    copyToken(token) {
      this.browserApi.clipboard.writeText(token);
    },
    toCancel() {
      this.token = null;
      this.selectedCourses = null;
      this.selectedSignatures = null;
      this.api_url = null;
      this.integration = null;
      this.integration_url = null;
      this.selectedTokenId = 0;
      this.$root.$emit('bv::hide::modal', 'modal-integration-webhook', '#btnShow');
    },
    getCourses() {
      this.loading = true;
      serviceCourse
        .read("/class/all")
        .then((resp) => {
          this.allCourses = resp;
          this.loading = false;
        });
    },
    getSignatures() {
      this.loading = true;
      serviceSignature
        .read("/all")
        .then((resp) => {
          this.AllSignatures = resp;
          this.loading = false;
        });
    },
    toIntegration() {
      this.integration = true;

      if(this.selectedCourses){
        this.selectedCourses.forEach((course) => {
          this.urlparams.push(`course_class_id[]=${course.id}`);
        });
      }
      if (this.selectedSignatures) {
        this.selectedSignatures.forEach((signature) => {
          this.urlparams.push(`signature_id[]=${signature.id}`);
        });
      }
    },
    filterToken() {
      const token = this.tokens.filter(token => token.id === this.selectedTokenId);
      this.selectedTokenValue = token[0].token;
      this.api_url = `${process.env.VUE_APP_API_HOST}/external/student`;
    },
  },
  watch: {
    selectedTokenId: function () {
      if (this.selectedTokenId) {
        this.filterToken();
        this.urlparams = [];
      }
    },
  },
  mounted() {
    this.getCourses();
    this.getSignatures();
    this.browserApi = navigator;
  }
}
</script>
<style lang="scss">
#modal-integration-webhook {
  z-index: 99999999999;

  .modal-header {
    border: none;
  }
}

.multiselect {
  -ms-overflow-style: none;
}

.multiselect__tags {
  background: var(--backgroundcolor);
}

.multiselect .multiselect__input,
.multiselect__single {
  background: var(--backgroundcolor);
}

.multiselect__content-wrapper {
  background: var(--backgroundcolor);
}

.multiselect__option--selected {
  background: var(--backgroundcolor);
}
</style>

<style lang="scss" scoped>
.url-show {
  word-wrap: break-word;
}

.header-token {
  display: block;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  background: rgba(217, 10, 10, 0.07);
  border-radius: 30px;
  padding: 10px;
  font-size: 1rem;
}

.logo {
  height: auto;
  margin-bottom: 40px;
  -webkit-box-align: self-start;
  -ms-flex-align: self-start;
  align-items: self-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.logo-svg {
  width: 93px;
}

.select-container option {
  background: var(--backgroundcolor) !important;
  color: var(--fontcolor);
}

.multi-select-container {
  display: block;
  margin-top: 30px;
  margin-bottom: 30px;
}

.token-block {
  width: 100%;
  height: auto;
  border: 1px solid black;
  border-radius: 12px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 15px;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-bottom: 15px;
}

#modal-integration-webhook {
  input {
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 5px;
    height: auto;
    font-size: 14px;
    padding: 3px;
    transition: .3s;
  }

  input:hover,
  input:focus {
    border: 1px solid #FC2B36;
    box-shadow: 0px 4px 10px rgba(148, 3, 3, 0.03);
    background: rgba(228, 0, 0, 0.05);
    color: var(--fontcolor);
    font-weight: normal;
  }

  input:focus {
    font-weight: 600;
  }

  .labelxx {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor);
    text-align: initial;
  }

  .allContentModal {
    padding: 0px 10px;
    margin: 0 auto;
    display: block;
    text-align: -webkit-center;
    margin-top: -30px;
  }

  .spaceText2 {
    margin-bottom: 20px;
  }

  .spaceText1 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .text1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.2px;
    color: #06c971;
    text-align: left;
  }

  .text2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.2px;
    color: var(--fontcolor);
  }

  .show-flex {
    display: flex;
  }

  .text3 {
    color: var(--fontcolor);
    font-size: 15px;
    text-align: left;
  }

  .spaceBtns {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .button {
    background: var(--backgroundcolor);
    border-radius: 6px;
    width: 200px;
    height: 45px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: var(--fontcolor);
    border: none;
    margin: 0 5px;
  }

  .button2 {
    background: var(--maincolor);
    border-radius: 6px;
    width: 200px;
    height: 45px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    border: none;
    margin: 0 5px;
  }
}
</style>